<template>
  <IconButton
    icon="add"
    :icon-classname="iconClassname"
    :classname="classname"
    @click="$emit('click')"
    :tooltip-text="tooltipText"
  />
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";

export default {
  name: 'IconButtonAdd',
  emits: ['click'],
  components: {IconButton},
  props: {
    iconClassname: {
      type: String,
      default: 'text-space-dark-blue'
    },
    classname: {
      type: String,
      default: 'bg-space-green'
    },
    tooltipText: {
      type: String,
      default: 'common.add'
    }
  }
};
</script>
