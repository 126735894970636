<template>
  <HomeLayout>
    <template #content>
      <div class="flex flex-col px-8 py-4 green-border bg-space-dark-blue space-y-4 h-full">
        <div class="flex flex-col items-center mb-10">
          <h1 class="geminis text-space-green text-4xl">
            {{ $t(`exercises.form.title`) }}
          </h1>
        </div>
        <div class="flex flex-col md:flex-row md:space-x-16">
          <div class="flex flex-col space-y-16 md:w-1/2 left-container">
            <TextFormInput
              :value="exercise.name"
              @input="exercise.name = $event"
              :label="$t('exercises.form.name')"
              :placeholder="$t('exercises.form.name_placeholder')"
              label-classname="!text-space-green font-bold text-2xl uppercase font-raleway-extra-bold"
              input-classname="!bg-white !text-space-dark-blue"
              wrapper-classname="!bg-white"
              :errors="errors"
              field="name"
            />
            <TextAreaFormInput
              :value="exercise.instruction"
              @input="exercise.instruction = $event"
              :label="$t('exercises.form.instruction')"
              :placeholder="$t('exercises.form.instruction_placeholder')"
              :errors="errors"
              field="instruction"
              textarea-classname="!bg-white !text-space-dark-blue"
              label-classname="!text-space-green font-bold text-2xl uppercase font-raleway-extra-bold"
            />

            <div class="flex flex-col space-y-16">
              <ExerciseRoundedList
                :can-show-add-button="canShowAddButton('requiredCodes')"
                :value="exercise.requiredCodes ? exercise.requiredCodes : []"
                value-prop="code"
                :help="$t('exercises.form.help_required_codes')"
                :label="$t('exercises.form.required_codes')"
                :placeholder="$t('exercises.form.required_codes_placeholder')"
                :errors="errors"
                @add="() => addEntry('requiredCodes')"
                @validate="(index) => validateEntry('requiredCodes', index)"
                @remove="(index) => removeEntry('requiredCodes', index)"
                @edit="(index) => editEntry('requiredCodes', index)"
                @input="(index, value) => onEntryEdited('requiredCodes', index, value, 'code')"
                :editing-index-list="editIndexList.requiredCodes"
              />
              <ExerciseRoundedList
                :can-show-add-button="canShowAddButton('clues')"
                :value="exercise.clues ? exercise.clues : []"
                :help="$t('exercises.form.help_clues')"
                :label="$t('exercises.form.clues')"
                :placeholder="$t('exercises.form.clues_placeholder')"
                :errors="errors"
                @add="() => addEntry('clues')"
                @validate="(index) => validateEntry('clues', index)"
                @remove="(index) => removeEntry('clues', index)"
                @edit="(index) => editEntry('clues', index)"
                @input="(index, value) => onEntryEdited('clues', index, value)"
                :editing-index-list="editIndexList.clues"
              />

            </div>
          </div>
          <div class="flex flex-col md:w-1/2">
            <div class="flex flex-col">
              <div class="flex justify-between items-center">
                <span class="text-space-green font-bold text-2xl uppercase font-raleway-extra-bold flex-grow">
                  {{ $t('exercises.form.initial_code') }}
                </span>
                <SelectFormInput
                  :value="exercise.language.toLowerCase()"
                  @input="(value) => updateLanguage(value)"
                  :placeholder="$t('exercises.language')"
                  :items="AVAILABLE_LANGUAGES()"
                  :errors="errors"
                  field="language"
                  wrapper-classname="!bg-white"
                  input-classname="!bg-white !text-space-dark-blue"
                  label-classname="!text-space-green font-bold text-2xl uppercase font-raleway-extra-bold"
                />
              </div>

              <div
                ref="editor"
                class="h-80 w-full overflow-y-hidden min-w-100"
              />
              <TextFormInput
                :value="exercise.expected_result"
                @input="exercise.expected_result = $event"
                :label="$t('exercises.form.expected_result')"
                :placeholder="$t('exercises.form.expected_result_placeholder')"
                label-classname="!text-space-green font-bold text-2xl uppercase font-raleway-extra-bold"
                input-classname="!bg-white !text-space-dark-blue"
                wrapper-classname="!bg-white"
                container-classname="mt-2"
                :errors="errors"
                field="expected_result"
              />
              <div class="flex flex-col mt-4">
                <div class="flex items-center space-x-6 mb-2">
                  <span class="text-2xl text-space-green font-raleway-extra-bold">{{
                    $t('exercises.form.access.label')
                  }}</span>
                  <ul class="list-none flex space-x-4">
                    <li
                      v-for="(visibility, index) in visibilities"
                      :key="index"
                    >
                      <CheckboxField
                        :label="$t('common.access.' + visibility).toString()"
                        :on-change="() => exercise.access = visibility"
                        :value="exercise.access && exercise.access === visibility"
                        checkbox-class="!border-white bg-white"
                        label-class="text-white"
                      />
                    </li>
                  </ul>
                </div>
                <HelpInfo
                  :help="$t('exercises.form.access.help')"
                  text-classname="text-gray-200"
                  icon-classname="!text-gray-200"
                />
              </div>
            </div>

            <PrimaryButton
              class="self-end justify-self-end bg-white h-10 !py-0 mt-10"
              classes="!text-space-dark-blue"
              :text="$t('exercises.form.submit')"
              @click="onSubmit"
            />
          </div>
        </div>
      </div>
      <ExerciseConfigurationModal
        v-model="exerciseModal"
        v-if="!!exerciseModal"
        :test="onTest"
      />
    </template>
  </HomeLayout>
</template>
<script>
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import ExercisesService from "@/services/ExercisesService";
import FormService from "@/services/formService";
import TextAreaFormInput from "@/components/inputs/TextAreaFormInput.vue";
import HomeLayout from "@/layouts/HomeLayout.vue";
import loader from "@monaco-editor/loader";
import ExerciseRoundedList from "@/components/List/ExerciseRoundedList.vue";
import ExerciseConfigurationModal from "@/components/modals/exercises/ExerciseConfigurationModal.vue";
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import SelectFormInput from "@/components/inputs/SelectFormInput.vue";
import {AVAILABLE_LANGUAGES} from "@/constants/languages/LanguagesConstants";
import {ROUTE_MY_EXERCISES_BUNDLES} from "@/router";
import {EXERCISES_BUNDLES_TABS} from "@/constants/exercices/ExercicesConstants";
import CheckboxField from "@/components/checkbox/CheckboxField.vue";
import HelpInfo from "@/components/help/HelpInfo.vue";
import {GAME_VISIBILITY_LIST} from "@/constants/games/GameVisibilityConstants";

export default {
  name: 'ExerciseConfiguration',
  components: {
    HelpInfo, CheckboxField,
    SelectFormInput,
    TextFormInput,
    ExerciseConfigurationModal,
    ExerciseRoundedList,
    HomeLayout, TextAreaFormInput, PrimaryButton
  },
  props: {},
  data() {
    return {
      exerciseModal: null,
      exercise: {
        mode: 'error',
        language: 'javascript',
        access: 'public',
      },
      editIndexList: {
        requiredCodes: undefined,
      },
      errors: {}
    };
  },
  //after data initialization
  async mounted() {
    if (this.$route.params.id) {
      await this.getExercise(this.$route.params.id);
      return;
    }
    await this.buildEditor();
  },
  async updated() {
    this.userEditor?.setValue(this.exercise.code);
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      await this.getExercise(to.params.id);
      await this.buildEditor();
    }
    next();
  },
  computed: {
    visibilities() {
      return GAME_VISIBILITY_LIST;
    }
  },
  methods: {
    getExercise(id) {
      ExercisesService.getExercise(id)
        .then((exercise) => {
          this.exercise = exercise;
          this.buildEditor();
        })
        .catch(() => {
          this.$router.push({
            name: ROUTE_MY_EXERCISES_BUNDLES.name,
            query: {
              tab: EXERCISES_BUNDLES_TABS[1].value
            }
          });
        });
    },
    updateLanguage(language) {
      this.exercise.language = language;
      this.buildEditor();
    },
    AVAILABLE_LANGUAGES() {
      return AVAILABLE_LANGUAGES.map((language) => ({
        value: language,
        label: language,
      }));
    },
    async buildEditor() {
      const monaco = await loader.init();
      this.userEditor?.dispose();
      this.userEditor = monaco.editor.create(this.$refs.editor, {
        value: this.exercise.code,
        language: this.exercise.language,
        theme: 'vs-dark',
      });

      this.userEditor.onDidChangeModelContent(() => {
        this.exercise.code = this.userEditor.getValue();
      });
    },
    canShowAddButton(field) {
      switch (field) {
      case 'requiredCodes':
        return !this.exercise?.requiredCodes || this.exercise?.requiredCodes?.length < 10;
      case 'clues':
        return !this.exercise?.clues || this.exercise?.clues?.length < 3;
      default:
        return false;
      }
    },
    addEntry(field) {
      this.exercise = {
        ...this.exercise,
        [field]: [...this.exercise[field] ?? [], field === 'clues' ? '' : {}]
      };
      this.editIndexList = {
        ...this.editIndexList,
        [field]: [...this.editIndexList[field] ?? [], this.exercise[field].length > 0 ? this.exercise[field].length - 1 : 0]
      };
    },
    validateEntry(field, indexToValidate) {
      this.editIndexList = {
        ...this.editIndexList,
        [field]: this.editIndexList[field].filter((index) => index !== indexToValidate)
      };
    },
    editEntry(field, indexToEdit) {
      this.editIndexList = {
        ...this.editIndexList,
        [field]: [...this.editIndexList[field] ?? [], indexToEdit]
      };
    },
    removeEntry(field, indexToDelete) {
      this.exercise[field] = this.exercise[field].filter((_, index) => index !== indexToDelete);
      this.rerangeIndexesEditing(field, indexToDelete);
    },
    rerangeIndexesEditing(field, indexToRemove) {
      this.editIndexList = {
        ...this.editIndexList,
        [field]: this.editIndexList?.[field].map((index) => index > indexToRemove ? index - 1 : index) ?? []
      };
    },
    onEntryEdited(field, index, value, prop) {
      // new value should be added to the array at the index
      const newArray = this.exercise?.[field]?.filter((_, i) => i !== index) ?? [];
      newArray.splice(index, 0, field === 'clues' ? value : {
        ...this.exercise[field][index],
        [prop]: value
      });
      this.exercise = {
        ...this.exercise,
        [field]: newArray
      };
    },
    onSubmit() {
      this.errors = {};
      const errors = ExercisesService.validateExerciseForm(this.exercise);
      if (FormService.hasErrors(errors)) {
        this.errors = errors;
        return;
      }

      const serviceFunction = this.exercise.id ? ExercisesService.updateExercise : ExercisesService.createExercise;
      serviceFunction(this.exercise)
        .then((exercise) => {
          this.exerciseModal = exercise;
          this.exercise = exercise;
        });
    },
    onTest(code){
      ExercisesService.testExerciseCreation(this.exercise.id, code)
        .then((response) => {
          if (response.error){
            this.$toast.error(response.error);
            return;
          }
          this.$toast.success(this.$t('exercises.activation_success'));
          this.$router.push({
            name: ROUTE_MY_EXERCISES_BUNDLES.name,
            params: {
              tab: EXERCISES_BUNDLES_TABS[1].value
            }
          });
        });
    }
  }
};
</script>
<style scoped>
.left-container {
  @apply pr-4;
  max-height: 70vh;
  overflow-y: auto;
}
</style>
