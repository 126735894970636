export const getLanguageIcon = (language) => (`fa-brands ${{
  'javascript': 'fa-js',
  'php': 'fa-php',
  'python': 'fa-python',
}[language]}`);

export const AVAILABLE_LANGUAGES = [
  'javascript',
  'php',
  'python'
];
export const getExercisesLanguagesIcons = (exercises) => {
  return [...new Set(exercises.map(exercise => exercise.language))]
    .filter(languageIcon => !!languageIcon)
    .map(languageIcon => getLanguageIcon(languageIcon));
};
