<template>
  <IconButton
    icon="trash"
    :icon-classname="iconClassname"
    :classname="classname"
    :tooltip-text="tooltipText"
    @click="$emit('click')"
  />
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";

export default {
  name: 'IconButtonDelete',
  emits: ['click'],
  components: {IconButton},
  props: {
    iconClassname: {
      type: String,
      default: 'text-white'
    },
    classname: {
      type: String,
      default: 'bg-red-500'
    },
    tooltipText: {
      type: String
    }
  }
};
</script>
