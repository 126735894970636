<template>
  <label class="checkbox"
         :class="checkboxClass"
  >
    <input
      :id="inputId"
      :disabled="disabled"
      type="checkbox"
      :checked="checked"
      @click="toggle"
    >
    <span
      class="checkbox__checkmark"
      :class="checked ? 'checked' : ''"
    />
  </label>
</template>
<script>
export default {
  name: 'PrimaryCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    checkboxClass: {
      type: String
    }
  },
  emits: ['input'],
  data() {
    return {
      checked: this.value
    };
  },
  watch: {
    value: function (val) {
      this.checked = val;
    }
  },
  methods: {
    toggle(event) {
      event.preventDefault();

      if (this.disabled){
        return;
      }
      this.$emit('input', this.checked);
    }
  }
};
</script>
<style scoped>
.checkbox {
  @apply rounded-full relative inline-block w-5 h-5 align-middle border border-space-dark-blue cursor-pointer;
}
.checkbox input {
  @apply opacity-0 h-0 w-0;
}

.checkbox__checkmark {
  @apply bg-space-green rounded-full left-0 top-0 absolute w-5 h-5 hidden;
}

.checkbox__checkmark.checked {
  @apply block;
}

.checkbox:has(.checkbox__checkmark.checked) {
   border: none;
}

:not(.checkbox:has(.checkbox__checkmark.checked)):not(.checkbox:has(.bg-white)):hover > .checkbox__checkmark {
  @apply block bg-space-dark-blue w-4 h-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.checkbox:has(.bg-white):not(.checkbox:has(.checkbox__checkmark.checked)):hover > .checkbox__checkmark {
  @apply bg-white;
}

.checkbox__checkmark.checked:hover {
  @apply opacity-60;
}
</style>
