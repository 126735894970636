<template>
  <div class="flex flex-col">
    <div class="flex flex-col space-y-2">
      <div class="flex justify-between">
        <span class="text-space-green font-bold text-2xl uppercase font-raleway-extra-bold">
          {{ label }}
        </span>
        <IconButtonAdd
          v-if="canShowAddButton"
          :tooltip-text="$t('common.add')"
          :classes="'!text-white'"
          @click="() => $emit('add')"
        />
      </div>

      <HelpInfo
        v-if="!!help"
        :help="help"
        icon-classname="!text-white"
        text-classname="!text-white"
      />

      <ul class="list-none">
        <li
          v-for="(object, index) in value"
          :key="index"
          class="flex font-bold justify-between items-center bg-white rounded-full px-4 py-1.5 my-2 pr-4 pl-6"
        >
          <span class="text-space-dark-blue">#{{ index }}</span>
          <span
            class="text-space-dark-blue"
            v-if="!editingIndexList.includes(index)"
          >
            {{ valueProp ? object[valueProp] : object }}
          </span>
          <TextAreaFormInput
            v-else
            :field="`value_${valueProp}_${index}`"
            :placeholder="placeholder"
            :value="valueProp ? object[valueProp] : object"
            @input="(newValue) => $emit('input', index, newValue)"
            textarea-classname="!bg-gray-100 !text-space-dark-blue  mx-6 !py-0 w-full my-2"
            container-classname="!w-full flex-grow"
            :max-lines="valueProp === 'code' ? 1 : undefined"
          />
          <span class="flex pl-6">
            <IconButton
              :tooltip-text="$t('common.validate')"
              v-if="editingIndexList.includes(index)"
              icon="check"
              icon-classname="!text-space-dark-blue"
              @click="$emit('validate', index)"
            />
            <IconButton
              v-if="!editingIndexList.includes(index)"
              :tooltip-text="$t('common.edit')"
              icon="edit"
              icon-classname="!text-space-dark-blue"
              @click="$emit('edit', index)"
            />
            <IconButtonDelete
              :tooltip-text="$t('common.delete')"
              @click="$emit('remove', index)"
            />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import HelpInfo from "@/components/help/HelpInfo.vue";
import IconButtonAdd from "@/components/buttons/IconButtonAdd.vue";
import IconButtonDelete from "@/components/buttons/IconButtonDelete.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import TextAreaFormInput from "@/components/inputs/TextAreaFormInput.vue";

export default {
  name: 'ExerciseRoundedList',
  emits: ['add', 'delete', 'edit', 'validate', 'input'],
  components: {TextAreaFormInput, IconButton, IconButtonDelete, IconButtonAdd, HelpInfo},
  props: {
    help: {
      type: String,
    },
    value: {
      type: Array,
      default: () => []
    },
    editingIndexList: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    valueProp: {
      type: String
    },
    canShowAddButton: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  }
};
</script>
