<template>
  <Modal
    v-if="!!value"
    :on-close="() => $emit('input', null)"
    :value="!!value"
  >
    <template #content>
      <div class="flex flex-col justify-center">
        <span class="text-2xl font-bold text-space-dark-blue">{{$t('exercises.form.modal.title')}}</span>
        <div class="flex flex-col mt-4">
          <span class="text-2xl text-space-dark-blue font-raleway-extra-bold">{{$t('exercises.instruction')}}</span>
          <p class="mt-2 text-space-dark-blue">{{ value.instruction }}</p>
        </div>
        <div class="mt-4 exercise-editor" ref="editor" />
        <PrimaryButton
          class="mt-4 w-1/3 self-center"
          bg-color="bg-space-dark-blue"
          @click="test(userEditor.getValue())"
          :text="$t('exercises.test')"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "@/components/modals/DefaultModal.vue";
import loader from "@monaco-editor/loader";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

export default {
  name: 'ExerciseConfigurationModal',
  emits: ['submit'],
  components: {PrimaryButton, Modal},
  props: {
    value: {
      type: Object,
      default: null,
    },
    test: {
      type: Function
    }
  },
  async mounted() {
    const monaco = await loader.init();
    this.userEditor = monaco.editor.create(this.$refs.editor, {
      value: this.value.code,
      language: this.value.language,
      theme: 'vs-dark',
      automaticLayout: false,
    });
  }
};
</script>
<style scoped>
.exercise-editor {
  min-height: 200px;
}
</style>
